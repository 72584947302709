import { Preferences } from "@capacitor/preferences";
import BackgroundGeolocation from "@transistorsoft/capacitor-background-geolocation";
import { BackgroundFetch } from "@transistorsoft/capacitor-background-fetch";
// plugins
import { NativeLogin } from "../plugins/NativeLogin";
// hooks
import { getDeviceId } from "../hooks/useDeviceInfo";
// services
import Constants from "./constants";
import i18n from "./i18n";
import { invalidateOldAccessToken } from "./authenticator";
import { getServerOption } from "./serverOption";
import { getRestHeaders } from "./rest-headers";
import { restartGeofencePlugin } from "./geofenceHelper";
// types
import type { Config } from "@transistorsoft/capacitor-background-geolocation";
import type { BackgroundFetchConfig } from "@transistorsoft/capacitor-background-fetch";
import type { GeofenceAccuracyLevel, JWT } from "../types/misc";

export const getGeofenceAccuracyLevel = async (): Promise<GeofenceAccuracyLevel> => {
	const { value } = await Preferences.get({ key: "geofence_accuracy_level" });
	const geofenceAccuracyLevelValue = value ?? Constants.GeofenceAccuracyLevels[2].value;
	const geofenceAccuracyLevel = Constants.GeofenceAccuracyLevels.find((geofenceAccuracyLevel) => (geofenceAccuracyLevel.value === geofenceAccuracyLevelValue));
	return geofenceAccuracyLevel ?? Constants.GeofenceAccuracyLevels[2];
};

export const setGeofenceAccuracyLevel = async (value: GeofenceAccuracyLevel["value"]): Promise<void> => {
	await Preferences.set({ key: "geofence_accuracy_level", value: value });
	const { locationTracking, settings } = await getGeofenceAccuracyLevel();
	const state = await BackgroundGeolocation.setConfig(settings);
	if (state.enabled) {
		if (state.trackingMode === 0 && locationTracking) {
			await BackgroundGeolocation.stop();
			await BackgroundGeolocation.start();
		} else if (state.trackingMode === 1 && !locationTracking) {
			await BackgroundGeolocation.stop();
			await BackgroundGeolocation.startGeofences();
		}
	}
};

const getDefaultGeofenceConfig = async (accessToken: JWT, refreshToken: JWT): Promise<Config> => {
	const { refreshCookieConfig, redirectUrl, geofenceEventUrl } = getServerOption();

	const { settings } = await getGeofenceAccuracyLevel();
	const deviceId = await getDeviceId();
	const restHeaders = await getRestHeaders();

	const params = new URLSearchParams({
		state: "nativeios", //"nativeionic",
		type: "geo",
	});
	const refreshUrl = `${redirectUrl}?${params.toString()}`;

	return {
		...settings,
		//desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
		//distanceFilter: 10,
		//stopTimeout: 5,
		logLevel: (process.env.BUILD_MODE === "production") ? BackgroundGeolocation.LOG_LEVEL_ERROR : BackgroundGeolocation.LOG_LEVEL_VERBOSE,
		logMaxDays: (process.env.BUILD_MODE === "production") ? 3 : 28,
		enableHeadless: true,
		stopOnTerminate: false,
		startOnBoot: true,
		locationAuthorizationAlert: i18n.t("geofence.iosLocationAuthorizationAlert", { returnObjects: true }),
		backgroundPermissionRationale: i18n.t("geofence.androidPopupAllowAllTheTime", { returnObjects: true }),
		notification: {
			smallIcon: "drawable/notification_icon",
			largeIcon: "", // empty string to remove (default) icon
			text: i18n.t("geofence.androidNotification.text"),
		},
		disableProviderChangeRecord: true,
		disableLocationAuthorizationAlert: false,
		//disableMotionActivityUpdates: true, if true will no longer ask the physical activity permission, which the plugin would need for better geofencing
		persistMode: BackgroundGeolocation.PERSIST_MODE_GEOFENCE,
		url: geofenceEventUrl,
		method: "POST",
		autoSync: true,
		geofenceTemplate: JSON.stringify({
			id: deviceId,
			gwId: "<%= geofence.identifier %>",
			loc: "<%= geofence.action %>",
			ts: "<%= timestamp %>",
		}),
		httpRootProperty: ".",
		headers: restHeaders,
		authorization: {
			strategy: "JWT",
			accessToken: accessToken,
			refreshToken: refreshToken,
			refreshUrl: refreshUrl,
			refreshPayload: {
				[refreshCookieConfig.name]: "{refreshToken}",
			},
			refreshHeaders: {
				Referer: "http://localhost/",
				...restHeaders,
			},
		},
	} as const satisfies Config;
};

const registerGeofenceListeners = async (): Promise<void> => {
	await BackgroundGeolocation.removeListeners();

	BackgroundGeolocation.onAuthorization(async ({ status, success, error, response }) => {
		if (success) {
			if (typeof response === "object" && typeof response.access_token === "string" && typeof response.refresh_token === "string" && typeof response.old_token_id === "string") {
				try {
					await NativeLogin.setTokens({
						accessToken: response.access_token,
						refreshToken: response.refresh_token,
					});

					await invalidateOldAccessToken(response.old_token_id);
				} catch (errorSetTokens) {
					console.error("error setting geolocation tokens", errorSetTokens);
				}
			} else {
				console.warn("onAuthorization invalid body response", status, response);
			}
		} else {
			console.warn("onAuthorization unsuccessful", status, error);
		}
	});
};

export const readyGeofence = async (): Promise<void> => {
	try {
		const { accessToken, refreshToken } = await NativeLogin.getTokens();

		if (accessToken !== "" && refreshToken !== "") {
			const config = await getDefaultGeofenceConfig(accessToken, refreshToken);
			const { enabled } = await BackgroundGeolocation.setConfig(config);

			await registerGeofenceListeners();

			if (enabled) {
				// TODO: fights the symptom of a rare bug, where the plugin is in a bugged state and can only be repaired by restarting it.
				void restartGeofencePlugin(); // long running task -> no await
			}
		} else {
			console.warn("tokens are empty in readyGeofence");
		}
	} catch (error) {
		console.error("readyGeofence catch", error);
	}
};

export const initBackgroundFetchIos = async (): Promise<void> => {
	// TODO: check if we could/should use `@capacitor/background-runner`
	const config = {
		// minimumFetchInterval: 15, // TODO: check if increase time to something like: "Math.max(1 day, refreshTokenExpireTime - 1 day)"
		// enableHeadless: true,
		// stopOnTerminate: false,
		// startOnBoot: true,
		// forceAlarmManager: false,
		// requiredNetworkType: BackgroundFetch.NETWORK_TYPE_NONE, // TODO: check to set `BackgroundFetch.NETWORK_TYPE_ANY` if we also use this plugin for Android
		// requiresBatteryNotLow: false,
		// requiresCharging: false,
		// requiresDeviceIdle: false,
		// requiresStorageNotLow: false,
	} as const satisfies BackgroundFetchConfig;

	try {
		await BackgroundFetch.configure(config, async (taskId) => {
			await NativeLogin.checkRefreshTokens({
				headers: await getRestHeaders(),
			});
			await BackgroundFetch.finish(taskId);
		}, async (taskId) => {
			console.info("initBackgroundFetchIos, timed out");
			await BackgroundFetch.finish(taskId);
		});
	} catch (error) {
		// https://developer.apple.com/documentation/uikit/uibackgroundrefreshstatus
		console.warn("backgroundrefreshstatus not available", error);
	}
};

import { EventEmitter } from "events";
import { Capacitor } from "@capacitor/core";
// plugins
import { AppState } from "../plugins/AppState";
import { NativeWebSocket } from "../plugins/NativeWebSocket";
// services
import { getServerOption } from "./serverOption";
import { getRestHeaders } from "./rest-headers";
import { setSocketId, checkIsAuthenticated } from "./authenticator";
// types
import type { PluginListenerHandle } from "@capacitor/core";
import type { MessageResponse, CloseResponse, ErrorResponse } from "../plugins/NativeWebSocket";
import type { Channel, WebSocketUrl } from "../types/config";
import type { RestHeaders } from "./rest-headers";
import type { MsgData, MsgDataUnknown, MsgDataWithPayload, MessageResponseHandler, FilterMsgByPayload, RxCallback } from "../types/roc-ws";
import type {
	HandlerId,
	MessageErrorRX,
	MessageTxKnown, PayloadTxKnown,
	MessageRxKnown, PayloadRxKnown,
	CmdKeepAlive, CmdLogin, MsgResponseLogin,
} from "../types/message";

const HEARTBEAT_SECONDS = 55;

const MSG_DATA_UNKNOWN = {
	reason: "unknown",
} as const satisfies MsgDataUnknown;

/**
 * @event ready
 * @event connecting
 * @event connected
 * @event disconnecting
 * @event disconnected
 * @event error
 * @event loginFailed
 * @event message-tx
 * @event message-rx
 */
class Glient extends EventEmitter {

	#channel: Channel | undefined = undefined;
	#url: WebSocketUrl | undefined = undefined;
	#restHeaders: RestHeaders | undefined = undefined;

	#ready = false;
	#connecting = false;
	#disconnecting = false;

	#messageResponseTimeouts = new Map<HandlerId, number>();
	#messageResponseHandlers = new Map<HandlerId, MessageResponseHandler>();

	#connectRetryId: number | undefined = undefined;
	#connectRetryIdShort: number | undefined = undefined;
	#heartbeatId: number | undefined = undefined;

	#useShortReconnect = true;
	#connectRetryTimeoutShort = 1000;
	#connectRetryTimeout = 5000;
	#appStateChangeRemoveHandler: PluginListenerHandle["remove"] | undefined = undefined;

	#webSocketReadyState: WebSocket["readyState"] = WebSocket.CLOSED;

	constructor() {
		super();

		this.setMaxListeners(32);

		this.handleSendHeartbeat = this.handleSendHeartbeat.bind(this);
		// setup ws event handlers
		this.handleWsOpen = this.handleWsOpen.bind(this);
		this.handleWsClose = this.handleWsClose.bind(this);
		this.handleWsMessage = this.handleWsMessage.bind(this);
		this.handleWsError = this.handleWsError.bind(this);
	}

	#cleanup(): void {
		window.clearTimeout(this.#connectRetryId);
		window.clearTimeout(this.#connectRetryIdShort);
		this.#stopHeartbeat();
	}

	async #setAppStateListeners(): Promise<void> {
		if (this.#appStateChangeRemoveHandler === undefined) {
			try {
				const { remove } = await AppState.addListener("appStateChange", async ({ state }) => {
					if (["active", "resume"].includes(state)) {
						this.#cleanup();
						await this.connect();
					} else if (this.#ready && !this.#disconnecting) {
						this.#cleanup();
						await this.disconnect();
					} else {
						this.emit("debugMessage", `App inactive ready=${this.#ready}, connecting=${this.#connecting}, disconnecting = ${this.#disconnecting}`);
					}
				});
				this.#appStateChangeRemoveHandler = remove;
			} catch (error) {
				console.error("AppState.addListener(appStateChange) failed", error);
			}
		}
	}

	async #setNativeWebSocketListeners(): Promise<void> {
		await NativeWebSocket.removeAllListeners();

		// TODO: gets cleaned up by `NativeWebSocket.removeAllListeners()`
		await NativeWebSocket.addListener("message", (event: MessageResponse) => {
			this.handleWsMessage(event as MessageEvent<string>);
		});

		// TODO: gets cleaned up by `NativeWebSocket.removeAllListeners()`
		await NativeWebSocket.addListener("close", (event: CloseResponse) => {
			this.#webSocketReadyState = WebSocket.CLOSED;
			this.handleWsClose(event as CloseEvent);
		});

		// TODO: gets cleaned up by `NativeWebSocket.removeAllListeners()`
		await NativeWebSocket.addListener("error", (event: ErrorResponse) => {
			this.#webSocketReadyState = WebSocket.CLOSED;
			this.handleWsError(event);
		});

		// TODO: gets cleaned up by `NativeWebSocket.removeAllListeners()`
		await NativeWebSocket.addListener("readyStateChanged", ({ readyState }) => {
			this.#webSocketReadyState = readyState;

			this.emit("debugMessage", `readyStateChanged ${readyState}`);

			switch (readyState) {
				case WebSocket.CONNECTING:
					this.#connecting = true;
					break;
				case WebSocket.OPEN:
					this.handleWsOpen();
					break;
				case WebSocket.CLOSING:
					this.#disconnecting = true;
					break;
				case WebSocket.CLOSED:
					// this.handleWsClose();
					break;
				default:
					console.warn("unknown readyState", readyState);
			}
		});
	}

	public async connect(): Promise<void> {
		if (Capacitor.isNativePlatform()) {
			await this.#setAppStateListeners();
		}

		if (this.#ready || this.#connecting || this.#disconnecting) {
			return;
		}

		this.#connecting = true;
		this.emit("connecting");

		const { channel, glientWsUrl } = getServerOption();

		this.#channel = channel;
		this.#url = glientWsUrl;
		this.#restHeaders = await getRestHeaders();

		try {
			await this.#setNativeWebSocketListeners();

			const isAuthenticated = await checkIsAuthenticated();
			if (isAuthenticated) {
				await NativeWebSocket.connect({ url: this.#url });
			} else {
				this.#connecting = false;
				const msgData = {
					reason: "loginFailed",
					payload: { message: "header auth failed" },
					url: this.#url,
				} as const satisfies MsgDataWithPayload;
				this.emit("loginFailed", msgData);
			}
		} catch (error) {
			this.#connecting = false;
			console.error(error);
			this.emit("debugMessage", "glient connect failed");
			this.emit("error", error);
			this.#reconnectWithTimeout();
		}
	}

	public async disconnect(msgData: MsgData = MSG_DATA_UNKNOWN): Promise<void> {
		if (this.#disconnecting) {
			return;
		}

		this.#disconnecting = true;
		this.emit("disconnecting");

		this.#abortAll();
		this.#cleanup();

		await NativeWebSocket.disconnect();
		await NativeWebSocket.removeAllListeners(); // TODO: change to single remove-listener handler

		setSocketId(undefined);

		this.#ready = false;
		this.#connecting = false;
		this.#disconnecting = false;
		this.emit("disconnected", msgData);
	}

	#reconnectWithTimeout(): void {
		if (this.#useShortReconnect) {
			this.#useShortReconnect = false;
			this.#connectRetryIdShort = window.setTimeout(async () => {
				await this.#reconnect();
			}, this.#connectRetryTimeoutShort);
		} else {
			this.#connectRetryId = window.setTimeout(async () => {
				await this.#reconnect();
			}, this.#connectRetryTimeout);
		}
	}

	async #reconnect(): Promise<void> {
		this.emit("debugMessage", `perform reconnect()`);
		this.once("disconnected", async () => {
			await this.connect();
		});
		await this.disconnect();
	}

	#startHeartbeat(): void {
		this.#stopHeartbeat();
		this.#heartbeatId = window.setInterval(this.handleSendHeartbeat, HEARTBEAT_SECONDS * 1000);
	}

	#stopHeartbeat(): void {
		window.clearInterval(this.#heartbeatId);
		this.#heartbeatId = undefined;
	}

	private handleSendHeartbeat(): void {
		const cmd = {
			action: "keepAlive",
			data: new Date().toISOString(),
		} as const satisfies CmdKeepAlive;
		this.send(cmd);
	}

	private handleWsOpen(/*event: Event*/): void {
		this.emit("connected");
		this.#useShortReconnect = true;
		this.#txLogin();
		this.on("message-rx", this.#messageRxHandler.bind(this));

		this.#startHeartbeat();
	}

	private handleWsClose(event: CloseEvent): void {
		setSocketId(undefined);

		this.#ready = false;
		// this.#disconnecting = true;
		// this.emit("disconnecting");

		this.#abortAll();
		this.off("message-rx", this.#messageRxHandler.bind(this));

		this.#cleanup();

		this.emit("debugMessage", `handleWSClosed event was clean? ${event.wasClean} - if not clean, we reconnectWithTimeout`);

		if (!event.wasClean) {
			this.#reconnectWithTimeout();
		}
	}

	private handleWsMessage(event: MessageEvent<string>): void {
		try {
			const payload = JSON.parse(event.data) as PayloadRxKnown;

			const msg = {
				dir: "RX",
				error: (payload.status === "error") ? new Error(payload.data ?? "Unknown error") : null,
				payload: payload,
				raw: event.data,
			} as const satisfies MessageRxKnown;
			this.emit("message-rx", msg);
		} catch (error) {
			const msg = {
				dir: "RX",
				error: error as Error,
				payload: null,
				raw: event.data,
			} as const satisfies MessageErrorRX;
			this.emit("message-rx", msg);
		}
	}

	private handleWsError(event: ErrorResponse): void {
		this.emit("error", event);
		this.emit("debugMessage", `App got error event ${event.message} - should reconnectWithTimeout`);
		this.#reconnectWithTimeout();
	}

	/**
	 * Use `const send = useSend()` hook if possible
	 * TODO: make async
	 */
	public send<P extends PayloadTxKnown>(payload: P, callback: RxCallback<P> | null = null, timeout: number = 30000): HandlerId {
		payload.channel ??= this.#channel!;
		payload.requestId ??= globalThis.crypto.randomUUID() as HandlerId;

		if (callback) {
			this.#setResponseListener(payload, timeout, callback);
		}

		NativeWebSocket.getReadyState().then(({ readyState }) => {
			this.#webSocketReadyState = readyState;
		}).catch((error) => {
			console.error("NativeWebSocket.getReadyState() failed", error);
		}).finally(async () => {
			await this.#trySend(this.#webSocketReadyState, payload, callback, timeout);
		});

		return payload.requestId;
	}

	async #trySend<P extends PayloadTxKnown>(readyState: WebSocket["readyState"], payload: P, callback: RxCallback<P> | null, timeout: number): Promise<void> {
		if (readyState === WebSocket.OPEN) {
			await this.#sendPayload(payload, timeout, callback);
		} else {
			this.once("ready", async () => {
				await this.#sendPayload(payload, timeout, callback);
			});
			if (readyState !== WebSocket.CONNECTING) {
				await this.connect();
			}
		}
	}

	async #sendPayload<P extends PayloadTxKnown>(payload: P, timeout: number, callback: RxCallback<P> | null = null): Promise<void> {
		const data = JSON.stringify(payload);

		try {
			await NativeWebSocket.send({ data: data });
			const msg = {
				dir: "TX",
				error: null,
				payload: payload,
			} as const satisfies MessageTxKnown;
			this.emit("message-tx", msg);
		} catch (error) {
			this.abort(payload.requestId!);

			const msg = {
				dir: "TX",
				error: error as Error,
				payload: payload,
			} as const satisfies MessageTxKnown;
			this.emit("message-tx", msg);
			if (callback) {
				callback(error as Error);
			} else {
				throw error as Error;
			}
		}
	}

	#setResponseListener<P extends PayloadTxKnown>(payload: P, timeout: number, callback: RxCallback<P>): void {
		const handlerId = payload.requestId!;

		if (timeout > 0) {
			const timeoutId = window.setTimeout(() => {
				this.abort(handlerId);
				callback(new Error(`Timeout! handlerId: ${handlerId}`));
			}, timeout);
			this.#messageResponseTimeouts.set(handlerId, timeoutId);
		}

		const messageResponseHandler: MessageResponseHandler<P> = (msg) => {
			this.abort(handlerId);

			if (msg.error === null) {
				callback(null, msg);
			} else {
				callback(msg.error);
			}
		};
		this.#messageResponseHandlers.set(handlerId, messageResponseHandler as unknown as MessageResponseHandler);
	}

	#messageRxHandler(msg: MessageRxKnown | MessageErrorRX) {
		if (msg.payload && "responseId" in msg.payload && this.#messageResponseHandlers.has(msg.payload.responseId)) {
			this.#messageResponseHandlers.get(msg.payload.responseId)?.(structuredClone(msg as FilterMsgByPayload<PayloadTxKnown>)); // TODO remove on event messaging rework
		}
	}

	#abortAll(): void {
		for (const handlerId of this.#messageResponseHandlers.keys()) {
			this.abort(handlerId);
		}
	}

	public abort(handlerId: HandlerId): boolean {
		window.clearTimeout(this.#messageResponseTimeouts.get(handlerId));
		this.#messageResponseTimeouts.delete(handlerId);
		return this.#messageResponseHandlers.delete(handlerId);
	}

	#txLogin(): void {
		this.off("message-rx", this.#rxLogin);
		this.once("message-rx", this.#rxLogin);

		const cmd = {
			action: "login",
			channel: this.#channel!,
			headers: this.#restHeaders!,
		} as const satisfies CmdLogin;
		this.send(cmd);
	}

	async #rxLogin(msg: MessageRxKnown | MessageErrorRX): Promise<void> {
		if (msg.payload?.info === "login") {
			const loginMsg = msg as MsgResponseLogin;
			this.#connecting = false;
			if (loginMsg.payload.status === "ok") {
				// logged in
				this.#ready = true;
				setSocketId(loginMsg.payload.data.socketId);
				this.emit("ready", structuredClone(loginMsg));
			} else {
				const msgData = {
					reason: "loginFailed",
					payload: loginMsg.payload,
					url: this.#url!,
				} as const satisfies MsgDataWithPayload;
				await this.disconnect(msgData);
			}
		}
	}

}

export default (new Glient());
